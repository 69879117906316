
import { Vue, Options, prop } from "vue-class-component";
import debounce from "lodash/debounce";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { DistrictData } from "@/domain/entities/Location";

class Props {
  modelValue = prop<any>({});
  placeholder = prop<string>({
    default: "Pilih kecamatan"
  });
  status = prop<string>({
    default: ""
  });
  keyName = prop<string>({
    default: "districtNameCityName"
  });
  keyValue = prop<string>({
    default: "code"
  });
  errorCaption = prop<string>({
    default: ""
  });
  isShowErrorIndicator = prop<boolean>({
    default: false
  });
  hideErrorIcon = prop<boolean>({
    default: false
  });
  paramsType = prop<string>({
    default: "",
    type: String
  });
  paramsExcludeCountry = prop<string>({
    default: "",
    type: String
  });
  isDisabled = prop<boolean>({
    default: false,
    type: Boolean
  });
}

@Options({
  emits: ["update:modelValue", "change"]
})
export default class SelectDistrict extends Vue.with(Props) {
  isLoadingDistrict = false;
  listDistrict: DistrictData[] = [];
  fetchDistrict = debounce(async (search: string) => {
    this.isLoadingDistrict = true;
    this.listDistrict = await LocationController.getDistrictList({
      search: search,
      status: this.status,
      page: 1,
      limit: 10,
      type: this.paramsType,
      excludeCountry: this.paramsExcludeCountry,
      cache: true
    });
    this.isLoadingDistrict = false;
  }, 250);
  get optionDistrict(): DistrictData[] {
    return this.listDistrict;
  }
  onBlur() {
    this.isLoadingDistrict = false;
  }
}
